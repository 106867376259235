/*
* Fix sticky
*/
.sticky-top{
   top: 50px;
}

/*
 * General
 */
 body,
.form-control,
.input-group-text,
.btn,
legend {
  	font-size: .875rem;
}

.form-control,
.btn {
    line-height: 1.5;
    border-radius: .2rem;
}

.form-control:not(.custom-select),
.input-group-text,
.btn {
	padding: .25rem .5rem;
}

.form-control {
	height: calc(1.75rem + 3px);
}

.form-control-plaintext {
	padding-top: .25rem;
	padding-bottom: .25rem;
}

fieldset .form-check-inline:last-child {
	margin-right: 0;
}

.custom-select {
	padding-top: .25rem;
	padding-bottom: .25rem;
}

.alert-dismissible .close {
	padding: .4rem 1.25rem;
}

.alert-dismissible .close span {
	display: block;
    line-height: 1.75rem;
    height: 2rem;
}

/*
 * Forms
 */
label .btn {
	vertical-align: baseline;
	line-height: 1;
	padding: 0;
}

.form-control.is-valid {
	border-color: #ced4da;
}

.form-check-input.is-valid~.form-check-label {
	color: #212529;
}

.form-control.is-valid:focus {
	border-color: #80bdff;
	-webkit-box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.form-control.is-invalid,
.form-control.is-valid {
	background-image: none;
}

/*
 * Content
 */
.card-header .title {
    cursor: text !important;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

/*
 * Datatables
 */
table.dataTable {
	width: 100% !important;
	border-collapse: collapse !important;
}

table.dataTable td, 
table.dataTable th {
	vertical-align: middle;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, 
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
    top: 50%;
    margin-top: -9px;
}
