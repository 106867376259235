/* bootstrap */
@import 'bootstrap/scss/bootstrap.scss';

/* fontawesome */
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';

/* sweet alert */
@import 'sweetalert2/src/sweetalert2.scss';

/* datatables */
@import 'datatables.net-src/css/dataTables.bootstrap4.scss';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';

/* theme */
@import 'themes/defaut';

/* sidebar */
@import 'partials/sidebar';
