/*
 * Sidebar
 */
[role="main"],
#sidebar {
	padding-top: 48px;
}

[role="main"]:not(.show) {
 	padding-left: 4.5rem;
}

[role="main"].show {
 	padding-left: 16.5rem;
}

#sidebar {
  	left: 0;
  	top: 0;
  	bottom: 0;
  	z-index: 100;
  	position: fixed;
  	background-color: #f6f6f6;
  	max-height: 100%;
	overflow-y: auto;
	white-space: nowrap;
}

#sidebar:not(.show) {
	width: 3rem;
}

#sidebar.show {
	width: 15rem;
}

#sidebar ul {
	list-style: none;
}

#sidebar > ul {
	padding-left: 0;
	margin-top: 1rem;
}

#sidebar > ul ul {
	padding-left: 0;
}

#sidebar .nav-item {
	position: relative;
}

#sidebar .nav-link {
	width: 100%;
	white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

#sidebar > ul ul .nav-link {
	padding-left: calc(1rem + 1.75em);
}

#sidebar > ul ul ul .nav-link {
	padding-left: calc(1rem + 3.5em);
}

#sidebar > ul ul ul ul .nav-link {
	padding-left: calc(1rem + 5.25em);
}

#sidebar > ul ul ul ul ul .nav-link {
	padding-left: calc(1rem + 7em);
}

#sidebar > ul ul ul ul ul ul .nav-link {
	padding-left: calc(1rem + 8.75em);
}

#sidebar > ul ul ul ul ul ul ul .nav-link {
	padding-left: calc(1rem + 10.5em);
}

#sidebar .nav-link,
#sidebar.show .title {
	display: inline-block;
}

#sidebar .nav-link.active {
  color: #0056b3;
}

#sidebar .title {
	max-width: 100%;
	vertical-align: top;
}

#sidebar .title.has-icon {
	margin-left: .5em;
	max-width: calc(100% - 1.75em);
}

#sidebar .title.has-dropdown {
	max-width: calc(100% - 1.25em);
}

#sidebar .title.has-icon.has-dropdown {
	max-width: calc(100% - 3em);
}

#sidebar .dropdown {
	position: absolute;
	top: 0;
	right: 0;
	padding: .5rem 1rem;
}

#sidebar .dropdown i {
	width: 0.75em;
}

#sidebar:not(.show) > ul ul,
#sidebar:not(.show) .title,
#sidebar:not(.show) .dropdown,
#sidebar.show .has-submenu:not(.expend) > ul {
	display: none;
}

#sidebar.show .dropdown,
#sidebar.show .has-submenu.expend > ul {
	display: block;
}

#sidebar.show .has-submenu.expend > .nav-link > .dropdown i {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

/*
 * Navbar
 */
.navbar-brand {
	padding: 0;
	margin: 0;
  	height: 48px;
  	line-height: 48px;
  	background-color: #262b2f;
  	width: 100%;
}

.navbar-brand a {
	display: inline-block;
	color: #ffffff;
	font-size: 1rem;
	text-decoration: none;
	vertical-align: top;
	outline: none;
}

.navbar-brand a img {
	height: 30px;
    float: left;
    margin-top: 9px;
    margin-right: 1rem;
}

#toggle-sidebar,
#disconnect {
	height: 48px;
}

#disconnect i {
	margin-right: .5em;
}

.navbar-nav .nav-item {
	line-height: 48px;
}

#toggle-sidebar.show i {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

@media (max-width: 575px) {

	/*
	 * Sidebar
	 */
	[role="main"],
	#sidebar {
		padding-top: 85px;
	}

	/*
	 * Navbar
	 */
	#toggle-sidebar,
	#disconnect {
		height: 37px;
	}

	.navbar-nav .nav-item {
		line-height: 37px;
	}
}

@media (min-width: 576px) and (max-width: 767px) {

	/*
	 * Navbar
	 */
	.navbar-brand {
	  	width: auto;
	}
}

@media (max-width: 767px) {

	/*
	 * Sidebar
	 */
	[role="main"]:not(.show),
	[role="main"].show {
	 	padding-left: 1.5rem;
	}

	#sidebar:not(.show) {
		width: 0;
	}

	#sidebar.show {
		width: 100%;
		bottom: auto;
  	 	-webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.075);
  	 	box-shadow: 0 .5rem 1rem rgba(0,0,0,.075);
	}
}

@media (min-width: 768px) {

	/*
	 * Navbar
	 */
	.navbar-brand {
	  	width: 15rem;
	}
}

@media (max-width: 991px) {

	/*
	 * Navbar
	 */
	#disconnect i {
		margin-right: 0;
	}

	#disconnect span {
		display: none;
	}
}

@media (min-width: 992px) {

	/*
	 * Sidebar
	 */
	[role="main"].expend {
	 	padding-left: 16.5rem;
	}

	#sidebar.expend {
		width: 15rem;
	}

	#sidebar.expend .title {
		display: inline-block;
	}

	#sidebar.expend .dropdown,
	#sidebar.expend .has-submenu.expend > ul {
    	display: block;
	}

	#toggle-sidebar.expend i,
	#sidebar.expend .has-submenu.expend > .nav-link > .dropdown i {
		-webkit-transform: rotate(90deg);
    	-ms-transform: rotate(90deg);
    	transform: rotate(90deg);
	}
}